<template>
  <div class="bg-cream-light py-10">
    <div class="gutters grid-layout grid-cols-12">
      <div
        class="type-heading-3 col-span-3 flex flex-col items-start space-y-1"
      >
        <NuxtLink to="/collections/all" @click="onDismiss">Shop all</NuxtLink>
        <NuxtLink to="/collections/oat-milks" @click="onDismiss"
          >Oat milks</NuxtLink
        >
        <div class="inline-flex items-center space-x-2">
          <NuxtLink to="/collections/bundles" @click="onDismiss"
            >Bundles</NuxtLink
          ><ProductSaveBadge />
        </div>
        <NuxtLink to="/collections/accessories" @click="onDismiss"
          >Accessories</NuxtLink
        >
      </div>
      <div
        v-for="product in products.slice(0, 3)"
        :key="product.id"
        class="col-span-3"
      >
        <ProductCard :product="product" small @click="onDismiss" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { data } = useCollectionQuery('oat-milks')

const products = computed(() =>
  [...(data.value?.collection?.products.nodes || [])].sort((a, b) =>
    a.title > b.title ? 1 : -1,
  ),
)

const emit = defineEmits(['dismiss'])

function onDismiss() {
  emit('dismiss')
}
</script>
